html, body {
  background-color: #b0e8be;
  height: 100%;
  min-height: 100vh;
  /*background-color: #93c29c;*/
  /*background-color: #a5c2aa;*/
}

.white-background {
  background-color: White;
  height: 100%;
  min-height: 100vh;
  display: block;
  margin-top: -1.4em;
  margin-bottom: -1.4em;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  padding-top:2em;
  padding-bottom:2em;
  padding-left:0.5em;
  padding-right:0.5em;
}

.App {
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.error {
  color: red;
  font-size: 1em;
  margin: 0 0 1em 0;
}

/* forms */
label {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

input, select {
  width: 100%;
  padding: 1em 1em;
  margin: 1em 0;
  display: inline-block;
  border: 0.1em solid #ccc;
  border-radius: 0.5em;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
}

.attending-yes {
  left: 1em;
}

.attending-no {
  left: 2em;
}

button[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 1em;
  margin: 1em 0;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
}

button[type=submit]:hover {
  background-color: #45a049;
}

/* box outs */
div.boxout {
  border-radius: 0.5em;
  background-color: #e6e6e6;
  padding: 1em 1em 1em;
  margin: 1em;
}

/* logout button */
button.logout:hover {
  background-color: #CB0B0B;
}

button.logout {
  background-color: #FE0101;
  color: white;
  padding: 1em;
  margin: 1em;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  float: right;
}

/* Tabs */
.tabs header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tabs header button {
  background-color: #f2f2f2;
  border: 0;
  border-radius: 0.5em 0.5em 0em 0em;
  padding: 1.5em 4em 1.5em;
}

.tabs header button.active {
  background-color: #e6e6e6;
  color: #000;
}

.tab-body {
  border-radius: 0em 0em 0.5em 0.5em;
  padding: 1em 1em 0.25em;
  margin: 0em 0em 1em;
  background-color: #e6e6e6;
}

.tabs {
  margin: 1em;
}

.tabs .tab-body > div {
  display: none;
}

.tabs .tab-body > div.active {
  display: block;
}

img {
  max-width:50em;
  max-height:50em;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.center-text {
  text-align:center;
}

.menu a {
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.menu-button {
  max-width:15%;
  max-height:15%;
}

.subheader {
  max-width:20%;
  max-height:20%;
}
